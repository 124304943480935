<template>
    <img id="close-btn" class="modal-hover-highlight" src="../assets/images/close.svg" @click="closeClicked">
    <img id="prev-btn" class="modal-hover-highlight" src="../assets/images/chev_left.svg" v-if="hasPrev" @click="prevClicked">
    <img id="next-btn" class="modal-hover-highlight" src="../assets/images/chev_right.svg" v-if="hasNext" @click="nextClicked">
    <div id="modal-content-parent">
        <div id="modal-content">
            <img :src="currentWork.cover">
            <div id="work-title"> {{ currentWork.title }} </div>
            <div id="work-desc"> {{ currentWork.desc }} </div>
            <a id="work-link" class="card" target="_blank" :href="currentWork.link.url">
                <span>{{ currentWork.link.name }}</span>
                <img src="../assets/images/chev_link.svg">
            </a>
        </div>
    </div>
</template>

<script>
import works from '../assets/data/works'

export default {
    name: 'work-modal',
    title: 'Works',
    props: {
        workId: Number, // the initial work id, might change due to navigation
    },
    data() {
        return {
            currentWorkId: this.workId
        }
    },
    works: works,
    methods:  {
        closeClicked() {
            this.$router.push('/works')
        },
        prevClicked() {
            this.currentWorkId -= 1
            this.$router.push(`/works/${this.currentWorkId}`)
        },
        nextClicked() {
            this.currentWorkId += 1
            this.$router.push(`/works/${this.currentWorkId}`)
        },
    },
    computed: {
        hasPrev() {
            return this.currentWorkId > 0
        },
        hasNext() {
            return this.currentWorkId < works.length-1
        },
        currentWork() {
            return works[this.currentWorkId]
        }
    },
    // mounted() {
    //     document.body.style.overflowX = 'hidden'
    // },
    // unmounted() {
    //     document.body.style.overflowX = 'scroll'
    // },
}
</script>

<style lang="scss" scoped>

@import '@/scss/modal';
@include modal-components;

#modal-content-parent #modal-content {
    padding: 4vw 0;
    margin: auto 15vw;

    &>* {
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        border-radius: 30px;
        object-fit: contain;
    }

    #work- {
        
        &title {
            color: #000000;
        }

        &desc {
            color: #000000;
            font: {
                weight: 400;
                size: 0.8em;
            };
        }

        &link {
            width: fit-content;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.5vw 4vw;
            border-radius: 1000px;
            cursor: pointer;
            
            span {
                font-size: 0.8em;
            }

            img {
                margin-left: 2vw;
                width: 3vw;
            }
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    #modal-content-parent {
        overflow-y: auto;
    }
}

@media screen and (min-width: $tabletWidth) {

    #modal-content-parent #modal-content {
        // copied from tablet card
        background: white;
        border-radius: 30px;
        border: 7px solid $borderColor;
        box-shadow: 4px 4px $shadowColor;

        // fixed size, manually position everything
        width: unquote("min(70vw, 1920px)");
        min-height: 65vh;

        position: relative;

        &>* {
            margin-bottom: 20px;
            position: absolute;
        }

        &>img {
            width: 32vw;
            height: 18vw;
            left: 2vw;
            top: 2vw;
            border-radius: 30px;
        }

        #work- {
            
            &title {
                color: #434343;
                width: 31vw;
                right: 2vw;
                top: 2vw;
            }

            &desc {
                left: 2vw;
                right: 2vw;
                top: 23vw;
                bottom: 0vw;
                overflow: scroll;
                color: #434343;
                font: {
                    weight: 300;
                    size: 0.8em;
                };
            }

            &link {
                width: 28vw;
                padding: 1vw;
                height: 2.2vw;
                border-radius: 1000px;
                top: calc(18vw - 3vw);
                right: 2vw;
                
                span {
                    font-size: 1em;
                }

                img {
                    margin: auto 0 auto unquote("min(4vw, 30px)");
                    width: auto;
                    height: 110%;
                }
            }
        }
    }
}

</style>